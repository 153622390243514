<template>
  <ion-page>
    <ion-content>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-header>
              <ion-card-title> Users </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item fill="outline" lines="none">
                  <ion-label position="floating"> Email </ion-label>
                  <ion-input class="ion-width-100" type="email" placeholder="someone@somewhere.org"
                    v-model="inviteUserEmail" :disabled="inviteInProgress">
                  </ion-input>
                  <ion-button slot="end" size="default" @click="inviteUser" :disabled="inviteInProgress">
                    <ion-spinner v-if="inviteInProgress" />
                    <template v-else>
                      Invite
                      <ion-icon slot="end" class="bi bi-envelope" />
                    </template>
                  </ion-button>
                </ion-item>
                <ion-item v-for="user of users" detail="false" :key="user.id">
                  <ion-icon slot="start" class="bi bi-person" />
                  <ion-buttons slot="end">
                    <ion-button size="small" fill="clear" :href="'admin/users/edit/' + user.id">
                      <ion-icon class="bi bi-pencil" />
                    </ion-button>
                    <ion-button size="small" fill="clear" @click="softDeleteUser(user)">
                      <ion-icon class="bi bi-trash" />
                    </ion-button>
                  </ion-buttons>
                  <ion-label>
                    <h2>{{ user.email }}</h2>
                    <p v-if="user.last_name && user.first_name">
                      {{ user.last_name }}, {{ user.first_name }}
                    </p>
                  </ion-label>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonContent,
  IonCard,
  IonInput,
  IonIcon,
  IonLabel,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonList,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonSpinner,
  alertController,
} from "@ionic/vue";

import { onMounted, ref } from "vue";
import { supabase } from "@/supabase/client";
import { getAllUsers } from "@/supabase/user";
import { mailSentStore } from "@/store/mail.js";

import {
  getAbsoluteLink,
  mailWasSent,
  sendingMailMustWait,
  timeLimitForSendingMails,
  calculateTimestampDifference
} from "@/utils";


const users = ref([]);
const inviteUserEmail = ref("");
const inviteInProgress = ref(false);
const mail = mailSentStore();
const oldTimestamp = ref(undefined);

onMounted(async () => {
  await updateUsers();
});

async function updateUsers() {
  users.value = await getAllUsers();
}

async function inviteUser() {
  if (!inviteUserEmail.value) {
    const errorInviteUser = await alertController.create({
      header: "Error",
      message: `Please insert an email address.`,
      buttons: ["OK"],
    });
    await errorInviteUser.present();
    return;
  }

  inviteInProgress.value = true;
  const currentTimestamp = Date.now();
  oldTimestamp.value = mail.sendMail[inviteUserEmail.value];
  const timestampDiff = calculateTimestampDifference(oldTimestamp.value, currentTimestamp);
  const limit = timeLimitForSendingMails();
  const remainTime = limit - timestampDiff;

  if (timestampDiff < limit) {
    inviteInProgress.value = false;
    const successMessage = await alertController.create({
      header: "Mail could not be sent",
      message: sendingMailMustWait(remainTime),
      buttons: ["OK"],
    });
    await successMessage.present();
  } else {
    const res = await supabase.functions.invoke("invite-user", {
      body: {
        email: inviteUserEmail.value,
        redirectTo: getAbsoluteLink("/resetPassword"),
      },
    });

    console.log(res);

    if (res.error || res.data?.error) {
      console.error(res.error, res.data?.error);

      const errorMessage = await alertController.create({
        header: "Error",
        message: `Failed to invite user. ${JSON.stringify(res)}`,
        buttons: ["OK"],
      });
      console.log(errorMessage);
      await errorMessage.present();
      inviteInProgress.value = false;
      return;
    }
    setTimeout(async () => {
      await updateUsers();
      const successMessage = await alertController.create({
        header: "Success",
        message: `User ${inviteUserEmail.value} has been invited.<br /><br />` + mailWasSent(),
        buttons: ["OK"],
      });
      await successMessage.present();
      inviteInProgress.value = false;
      mail.setTimestampByMail(inviteUserEmail.value, currentTimestamp);
      inviteUserEmail.value = "";
    }, 1000);
  }
}

async function softDeleteUser(user) {
  console.log("softDeleteUser", user);
  const choice = confirm(
    `Are you sure you want to delete the user ${user.email}?`
  );
  if (!choice) {
    return;
  }

  const res = await supabase
    .from("profiles")
    .update({ hide: true })
    .eq("id", user.id);


  const res2 = await supabase.functions.invoke("delete-user", {
    body: {
      id: user.id,
    },
  });
  await updateUsers();
}
</script>
