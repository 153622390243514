<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title>Info Editor</ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item v-for="(item, key) in info" :key="key">
          <template v-if="!reservedKeys.includes(key)">
            <ion-label position="stacked">{{ key }}</ion-label>
            <ion-input
              :value="item"
              @change="setValue(key, $event.target.value)"
            ></ion-input>
            <ion-checkbox
              slot="end"
              :checked="includeInFilename.includes(key)"
              @ionChange="onCheckbox(key, $event.target.checked)"
            ></ion-checkbox>
          </template>
        </ion-item>
        
      </ion-list>
    </ion-card-content>
  </ion-card>
</template>

<script setup>
import { IonList, IonCheckbox } from "@ionic/vue";
import { ref, onMounted } from "vue";
import { useMetaStore } from "@/store/meta";

/*
<ion-button 
slot="start" 
fill="clear" 
@click="addField"
>
<ion-icon class="bi bi-trash" />
</ion-button>

<ion-item>
<ion-button 
slot="start" 
fill="clear" 
@click="addNewField"
>
<ion-icon class="bi bi-plus" />Add Field
</ion-button>
<ion-label position="floating">key</ion-label>
<ion-input v-model="newKey" />
</ion-item>
*/

const reservedKeys = ref(["includeInFilename"]);

const props = defineProps({
  storeKey: {
    type: String,
    default: "info",
  },
  keys: {
    type: String,
    default: "",
  },
  includeInFilename: {
    type: String,
    default: "",
  },
});

const info = ref({});
const newKey = ref("");
const store = useMetaStore();
const includeInFilenameKey = ref([]);

onMounted(() => {
  const storedInfo = store.get(props.storeKey);
  console.log(props.keys);
  for (const key of props.keys.split(",")) {
    addField(key);
  }
  for (const key of props.includeInFilename.split(",")) {
    onCheckbox(key, true);
  }
  for (const key in storedInfo) {
    setValue(key, storedInfo[key]);
  }
});

function addField(key) {
  if (!key) return;
  if (reservedKeys.value.includes(key)) return;
  if (info.value[key]) return;
  info.value = { ...info.value, [key]: "" };
  console.log(info.value);
  newKey.value = "";
}

function addNewField() {
  addField(newKey.value);
}

function setValue(key, value) {
  info.value = { ...info.value, [key]: value };
  console.log(info.value);
  store.set(props.storeKey, info.value);
}

function onCheckbox(key, checked) {
  console.log(key, checked);
  if (checked) {
    includeInFilenameKey.value.push(key);
  } else {
    includeInFilenameKey.value = includeInFilenameKey.value.filter((k) => k !== key);
  }
  setValue("includeInFilename", includeInFilenameKey.value );
  console.log(includeInFilenameKey.value);
}
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
