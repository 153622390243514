<template>
  <ion-page>
    <ion-content>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-header>
              <ion-card-title> CICADA </ion-card-title>
              <ion-card-subtitle>
                Compolytics Integrated Control Application for Devices and
                Analysis
              </ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item>
                  <ion-label>Version</ion-label>
                  <ion-note slot="end"> {{ versionString }}, {{ version2 }}</ion-note>
                </ion-item>
                <ion-item>
                  © {{ currentYear }} Compolytics, &nbsp;
                  <a href="https://compolytics.com/" target="_blank">
                    www.compolytics.com
                  </a>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import { IonCol, IonRow } from "@ionic/vue";
import { version } from "@/../package.json";
import { ref } from "vue";

const versionString = ref(version);

const version2 = ref( GIT_DESCRIBE.hash );

const currentYear = ref( new Date().getFullYear() );


</script>

<style></style>
