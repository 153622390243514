
import { v4 as uuid4 } from "uuid";

export const dataMessage = () => {
  return {
    // unique identifier of the message
    uuid: uuid4(),
    // timestamp of the moment the message was created
    timestamp: Date.now(),
    // the source of the message, should be the name of the module
    source: "Undefined",
    // the type of the message, that indicates if the data is 
    // raw sensor data, or if has been processed / calibrated
    // could also be "image" to indicate that the data is an image.
    type: "raw",
    // the shape of the data, an array of integers that indicates
    // the number of elements in each dimension of the data
    shape: [],
    // configuration of the creating module, this should include
    // all the parameters that are necessary to reproduce the data
    config: {},
    // metadata of the data. this should include all information
    // that is necessary to understand the data, but that is not
    // necessary to reproduce it
    meta: {
      xLabel: undefined, // array of strings
      yLabel: undefined, // array of strings
      zLabel: undefined, // array of strings
      units: undefined, // array of strings [x, y, z ...]
      description: undefined, // string
      xColor: undefined, // array of html color codes
      yColor: undefined, // array of html color codes
    },
    // the data itself, this should be an array of arrays corresponding
    // to the shape of the data.
    values: [],

    // calibration data, this should include all the information
    // that is necessary to convert the raw data into a calibrated
    // data, list of single calibration measures on reference pads
    // Expected fields:
    // trueValuePercentage : undefined, // rated reflectance value in percentage for calibration pad, float value between 0 and 100
    // trueValueFactor : undefined, //  rated reflectance value as ratio for calibration pad, float value between 0 and 1
    // sensorValue: [] measured sensor values as produced by sendor
    calibration: [],
    
    // Single array with the dark current measurement
    // of the current measurement. 
    // undefined, if dark current is unavailable.
    // should have dimensions 1 x numberOfSensorChannels.
    darkCurrent: undefined, 

    // In case we have a autoexposure, we can store dark current per LED
    // should have dimension numberOfLEDs x numberOfSensorChannels
    perLEDDarkCurrent: undefined

  };
}
