import { supabase } from "@/supabase/client";


const getCurrentlyLoggedInUser = async () => {
  const {data, error} = await supabase.auth.getUser();
  if (error) {
    console.error(error);
    throw error;
  }
  return data.user;
}

const isAdminUser = async ( userId ) => {
  const adminRes = await supabase
        .from("admins")
        .select("id")
        .eq("user_id", userId )
        .limit(1);
  if (adminRes.error) {
    console.error(adminRes.error);
    return false;
  }
  return adminRes?.data?.length > 0;
}

const getUserProfile = async ( userId ) => {
  const {data, error} = await supabase
        .from("profiles")
        .select("*")
        .eq("id", userId )
        .limit(1);
  if (error) {
    console.error(error);
    return {};
  }
  return data[0];
}

async function getAllUsers() {
  const res = await supabase
    .from("profiles")
    .select()
    .eq("hide", false)
    .order("email");  

  if (res.error) {
    console.log(res.error);
    return [];
  }
  return res.data;
}


export { getCurrentlyLoggedInUser, isAdminUser, getUserProfile, getAllUsers };