<template>
  <ion-page>
    <ion-content>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <div v-if="props.id != null">
            <ion-button 
              class="ion-padding" 
              style="text-transform: none;"
              @click.prevent="goToUserOverview()"
              size="small"
            >
              Back to User list
            </ion-button>
            <br />
            <br />
          </div>
          <ion-card>
            <ion-card-header>
              <ion-card-title>Profile</ion-card-title>
              <ion-card-subtitle>{{ email }}</ion-card-subtitle>
            </ion-card-header>
            <ion-card-content>
              <br />
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <h2>Update your profile</h2>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <smart-input-item
                    v-model="firstname"
                    label="Firstname"
                    hint="Enter a Firstname"
                    :disabled="!isOnline"
                  />
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <smart-input-item
                    v-model="lastname"
                    label="Lastname"
                    hint="Enter a Lastname"
                    :disabled="!isOnline"
                  />
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <smart-input-item
                    v-model="company"
                    label="Company"
                    hint="Enter your company"
                    :disabled="!isOnline"
                  />
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col 
                  style="text-align: center" 
                  size="12" 
                  size-sm="4"
                >
                  <submit-button
                    label="Save"
                    @click="saveProfile"
                    :disabled="!isOnline"
                    :processing="processing"
                    :done="isProfileUpdated"
                    :tristate="true"
                  ></submit-button>
                </ion-col>
              </ion-row>
            </ion-card-content>
            <ion-buttons v-if="props.id == null">
              <ion-button
                @click="logout"
                color="primary"
                expand="block"
                fill="outline"
              >
                Logout
              </ion-button>
              <ion-button
                @click="changePassword"
                :disabled="!isOnline"
                color="primary"
                expand="block"
                fill="outline"
              >
                Change Password
              </ion-button>
            </ion-buttons>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-header>
              <ion-card-title>Assigned apps to user</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-list>
                <ion-item
                  v-for="app in assignedAppsToUser"
                  :key="app.id"
                  style="cursor: pointer;"
                  @click="gotoApp(app.id)">
                  <div class="appImageContainer">
                    <img 
                    v-if="app.image_url" 
                    v-bind:src="app.image_url" 
                    style="max-width:64px;" 
                  />
                  </div>
                  <p class="appTitle">{{ app.name }}</p>
                </ion-item>
              </ion-list>
            </ion-card-content>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
    <ion-footer> </ion-footer>
  </ion-page>
</template>

<script setup>
import { supabase } from "@/supabase/client";
import { ref, onMounted } from "vue";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { useAuthStore } from "@/store/auth.js";
import { useAppStateStore } from "@/store/app";
import {
  IonPage,
  IonButton,
  IonFooter,
  IonItem,
  alertController,
  IonCol,
  IonRow,
  IonList
} from "@ionic/vue";
import SmartInputItem from "@/components/SmartInputItem.vue";
import SubmitButton from "@/components/SubmitButton.vue";
import { getAbsoluteLink } from "@/utils";
import { getCurrentlyLoggedInUser, isAdminUser, getUserProfile } from "@/supabase/user";
import { getAppsForUser, getAvailableApps } from "@/supabase/app";
import { Network } from '@capacitor/network';


const props = defineProps(['id']);
const auth = useAuthStore();
const appState = useAppStateStore();
const assignedAppsToUser = ref([]);
const userProfile = ref({});
const router = useRouter();
const firstname = ref("");
const lastname = ref("");
const company = ref("");
const currentUserId = ref("");
const processing = ref(false);
const isProfileUpdated = ref(false);
const isAdmin = ref(false);
const isOwnProfile = ref(true);
const email = ref("");
const emailSent = ref(false);
const currentUser = ref({});
const editUserId = ref("");
const isOnline = ref(true);
const authProfile = auth.user.profile;

onMounted(async () => {
  const currentStatus = await Network.getStatus();
  isOnline.value = currentStatus.connected;

  if(isOnline.value){
    try {
      currentUser.value = await getCurrentlyLoggedInUser();
      console.log( "current user", currentUser.value);
      currentUserId.value = currentUser.value.id;
    } catch (error) {
      console.error(error);
      //router.go({ name: "Login" });
    }
    editUserId.value = currentUserId.value;
    if (props.id) {
      editUserId.value = props.id;
    }
    isOwnProfile.value = false;
    if ( editUserId.value === currentUserId.value ) {
      isOwnProfile.value = true;
    }

    if ( !isOwnProfile.value ) {
      isAdmin.value = await isAdminUser(currentUserId.value); 
    }

    if ( !isOwnProfile.value && !isAdmin.value ) {
      console.log( "hello" );
      //router.go({ name: "Profile" });
    }
  }
  
  await getDataFromUserProfile();
  
  if(isOnline.value){
    const userApps = await getAppsForUser(editUserId.value);
    appState.saveAppsForUser(userApps);
    const availableApps = await getAvailableApps();
    appState.saveAvailableApps(availableApps);  
  }
  assignedAppsToUser.value = appState.storedAppsForUser;
});

async function getDataFromUserProfile(){
  if(isOnline.value){
    userProfile.value = await getUserProfile(editUserId.value);
    console.log("userProfile", userProfile.value)
    email.value = userProfile.value.email;
    firstname.value = userProfile.value.first_name;
    lastname.value = userProfile.value.last_name;
    company.value = userProfile.value.company;
  }else{
    email.value = authProfile.email;
    firstname.value = authProfile.first_name;
    lastname.value = authProfile.last_name;
    company.value =authProfile.company;
  }
}

/*
async function getOnlineStatus(e) {
  isOnline.value = e;
}*/
Network.addListener('networkStatusChange', status => {
  isOnline.value = status.connected;
});

async function saveProfile() {
  processing.value = true;
  const updateUserRes = await supabase
    .from("profiles")
    .update({
      first_name: firstname.value,
      last_name: lastname.value,
      company: company.value,
    })
    .eq("id", editUserId.value)
    .select();
  console.log("supabase user:", updateUserRes);

  if (updateUserRes.error) {
    processing.value = false;
    console.error("bad", updateUserRes.error);

    await showingErrors(
      "Profil konnte nicht gespeichert werden. ",
      updateUserRes.error
    );
    return;
  } else {

    if(currentUserId.value == editUserId.value){
      authProfile.first_name = firstname.value;
      authProfile.last_name = lastname.value;
      authProfile.company =  company.value;
    }

    processing.value = false;
    isProfileUpdated.value = true;
    setTimeout(() => {
      isProfileUpdated.value = false;
    }, 1000);
  }
}

async function showingErrors(message, error) {
  const updateprofileError = await alertController.create({
    message: message + error,
    buttons: ["OK"],
  });
  await updateprofileError.present();
}

async function logout(){
  if(!isOnline.value){
    await logoutOffline();
  }else{
    await onLogout(); 
  }
}

async function onLogout() {
  if(isOnline.value){
   await supabase.auth.signOut();
  }
  auth.setLoggedIn(undefined);
  appState.saveAppsForUser(undefined);
  appState.saveAvailableApps(undefined);
  appState.loaded = undefined;

  if(isOnline.value){
    router.go({ name: "Login" });
  }else{
    router.push({ path: "/login" });
  }
}

async function logoutOffline() {

const setLogout = await alertController
    .create(
        {
            message: "Note: For a new login you need an active internet connection. Are you sure you want to log out?",
            buttons: [{
                text: 'Ok',
                handler: () => {
                  onLogout();
                }
            },
                'Cancel'
            ]
        }
    );
await setLogout.present();
}

async function changePassword(){
  email.value = userProfile.value.email;
  let alertMessage = undefined;

  const response = await supabase.auth.resetPasswordForEmail(email.value, {
    redirectTo: getAbsoluteLink("/resetPassword"),
  });
  console.log(response);
  if (!response.error) {
    emailSent.value = true;
  }

  if(emailSent.value){
    alertMessage = "A Password recovery link was sent to your email.";
  }else{
    alertMessage = "Could not send a password recovery link. Something went wrong:\n"+response.error;
  }
  const emailSendConfirm = await alertController.create({
    message: alertMessage,
    buttons: ["OK"],
  });
  await emailSendConfirm.present();
}

function goToUserOverview() {
  router.push({ path: "/admin/users" });
}

function gotoApp(appId) {
  router.push(`/app/${appId}`);
}

onBeforeRouteLeave(() => {
  if (!isOwnProfile.value) {
    const answer = window.confirm('All changes are saved?')
    if (!answer) return false;
  }
});
</script>

<style scoped>

.appImageContainer {
  width: 20%;
  text-align: center;
}
.appTitle {
  text-align: center;
  font-size: 1.2em;
  margin-left: 2em;
}
</style>
