<template>
  <ion-card>
    <ion-card-header>
      <ion-card-title> Data Browser </ion-card-title>
    </ion-card-header>
    <ion-card-content>
      <ion-list>
        <ion-item lines="full">
          <ion-buttons class="fileManagementButtons">
            <ion-button
              class="fileButton"
              @click="uploadFile()"
              color="primary"
              fill="outline"
            >
              <ion-icon class="bi bi-cloud-arrow-up" />&nbsp;Upload
            </ion-button>
            <ion-button
              class="fileButton"
              @click="downloadFile()"
              color="primary"
              fill="outline"
            >
              <ion-icon class="bi bi-hdd" />&nbsp;Export
            </ion-button>
            <ion-button
              class="fileButton"
              @click="removeFile()"
              color="danger"
              fill="outline"
            >
              <ion-icon class="bi bi-trash" />&nbsp;Delete
            </ion-button>
          </ion-buttons>
        </ion-item>
        <ion-item lines="full">
          <ion-chip color="light" slot="start">
            <ion-checkbox
              id="mainSelection"
              v-model="isMainChecked"
              @ionChange="setAllOnOff($event)"
            ></ion-checkbox>
          </ion-chip>
          <ion-button slot="start" @click="invertSelection">
            Invert
          </ion-button>
          <ion-text 
            :color="selectedFiles>0 
                   ? 'primary' 
                   : isDarkMode() 
                     ? '#ffffff' 
                     : '#000000'"
          >
            {{ selectedFiles }} of 
            {{ dataFiles.length }} selected
          </ion-text>
        </ion-item>
        <ion-item lines="none" />
        <ion-item v-for="file in dataFiles" :key="file.uuid">
          <ion-chip slot="start" color="none">
            <ion-checkbox
              :id="file.uuid"
              v-model="file.checked"
              @ionChange="setOnOff($event, file)"
            />
          </ion-chip>
          <ion-text>
            <h5 class="fileTitle">
              {{ file.filename }}
            </h5>
            <div class="fileDetails">
              <div class="fileIcons">
                <ion-icon
                  v-if="file.isUp"
                  class="bi bi-cloud-arrow-up-fill uploadStatus"
                  color="primary"
                />
                <ion-icon
                  v-else
                  class="bi bi-cloud-arrow-up uploadStatus"
                  color="medium"
                />
                <ion-icon
                  v-if="file.isExported"
                  class="bi bi-hdd-fill uploadStatus"
                  color="primary"
                />
                <ion-icon
                  v-else
                  class="bi bi-hdd uploadStatus"
                  color="medium"
                />
              </div>
              <div class="fileDate">
                {{ new Date(file.createdAt).toLocaleString() }}
              </div>
            </div>
            <data-tree :data="file"> </data-tree>
          </ion-text>
        </ion-item>
      </ion-list>
    </ion-card-content>
    <ion-button fill="clear" @click="loadFiles">Refresh</ion-button>
  </ion-card>
</template>

<script setup>
import {
  IonList,
  IonIcon,
  IonCheckbox,
  IonChip,
  IonText,
  loadingController,
  alertController,
} from "@ionic/vue";
import { 
  getDataFiles, 
  readDataFile, 
  deleteDataFile, 
  writeDataFile
} from "@/utils/filesystem";

import { ref, onMounted } from "vue";
import { supabase } from "@/supabase/client";
import { useAuthStore } from "@/store/auth";
import { useAppStateStore } from "@/store/app";
import DataTree from "@/components/DataTree.vue";
import { isDarkMode } from "@/utils/index";
import { events } from "@/utils/events";

const dataFiles = ref([]);
let checkedData = [];
const appState = useAppStateStore();
const authStore = useAuthStore();
let uploading = undefined;
let status = undefined;
const isMainChecked = ref(false);
const selectedFiles = ref(0);

// eslint-disable-next-line
const props = defineProps({
  filter: {
    type: Array,
    default: () => {
      return ["sameApp"];
    }, // ['sameApp', 'sameUser']
  },
});

onMounted(async () => {
  uploading = await loadingController.create({
    message: "Data will be uploaded, please be patient.",
  });
  loadFiles();
  // Subscript to control events
  events.on("control", onControl);
});

const controlMessages = {
  "browser.refresh": loadFiles,
}

// Carry out the action based on the message
// Only if listed in controlMessage
function onControl(message) {
  console.log("onControl", message);
  for (const key in controlMessages) {
    if ( key === message ) {
      controlMessages[key]();
    }
  }
}

async function loadFiles() {
  const loadedFiles = [];
  checkedData = [];
  isMainChecked.value = false;
  const files = await getDataFiles();
  for (const file of files) {
    console.log(file);
    try {
      const fileContent = JSON.parse(await readDataFile(file.name));
      console.log(fileContent);
      if (
        props.filter.includes("sameApp") &&
        fileContent.appId !== appState.id
      ) {
        continue;
      }
      if (
        props.filter.includes("sameUser") &&
        fileContent.userId !== authStore.user.id
      ) {
        continue;
      }
      loadedFiles.push(fileContent);
    } catch (e) {
      console.log(e);
    }
  }
  loadedFiles.sort((a, b) => {
    return a.createdAt < b.createdAt ? 1 : -1;
  });
  dataFiles.value = loadedFiles;

  console.log("dataFiles.value.length", dataFiles.value.length);
  if(dataFiles.value.length == 0){
    selectedFiles.value = 0;
  }

  await isUploaded();
}

async function uploadFile() {
  if (checkedData.length === 0) {
    await getStatus(
      "No data files are select. Please select at least one item."
    );
    await status.present();
  } else {
    uploading.present();

    const cleanDataFilesForUpload = [];
    for (const item in checkedData) {
      const fileString = JSON.parse(checkedData[item].fileString);
      delete fileString["checked"];
      delete fileString["isUp"];
      cleanDataFilesForUpload.push({
        id: checkedData[item].uuid,
        filestring: fileString,
      });
    }
    console.log(cleanDataFilesForUpload);
    const { _data, error } = await supabase
      .from('datafiles')
      .upsert(cleanDataFilesForUpload)
      .select();

    uploading.dismiss();

    if (error) {
      await getStatus(
        "Data could not be uploaded. Error:" + JSON.stringify(error.message)
      );
    } else {
      await getStatus("Data uploaded successful.");
    }
    await status.present();

    await loadFiles();
  }
}

async function getStatus(statusMessage) {
  status = await alertController.create({
    message: statusMessage,
    buttons: ["OK"],
  });

  return status;
}

async function removeFile() {
  if (checkedData.length === 0) {
    await getStatus(
      "No data files are select. Please select at least one item."
    );
    await status.present();
  } else {
    const confirmation = await alertController
    .create(
      {
        message: "Selected data will be deleted. Are you sure?",
        buttons: [{
          text: 'Ok',
          handler: () => {
            onDeleteData();      
          }
        },
          'Cancel'
        ]
      }
    );
  await confirmation.present();
  }
}

async function onDeleteData(){
    selectedFiles.value = 0;
    for (const item in checkedData) {
      //selectedFiles.value --;
      const jsonStr = JSON.parse(checkedData[item].fileString);

      console.log(
        "check",
        jsonStr.checked +
          " jsonStr.filename: " +
          jsonStr.filename +
          " jsonStr.uuid: " +
          jsonStr.uuid
      );
      try {
        if (jsonStr.checked == true) {
          console.log("is true");
          await deleteDataFile(jsonStr.filename);
        }
      } catch (error) {
        await getStatus(
          "Data could not be deleted. Error:" + JSON.stringify(error.message)
        );
        await status.present();
      }
    }
    await loadFiles();
}

async function downloadFile() {
  if (checkedData.length === 0) {
    await getStatus(
      "No data files are select. Please select at least one item."
    );
    await status.present();
  } else {
    const cleanDataFilesForDownload = [];
    const propertiesToBeRemoved = ["checked", "isUp", "isExported"];
    for (const item in checkedData) {
      const fileString = JSON.parse(checkedData[item].fileString);
      for (const property of propertiesToBeRemoved) {
        delete fileString[property];
      }
      cleanDataFilesForDownload.push(fileString);
    }

    const text = JSON.stringify(cleanDataFilesForDownload);
    const filename = getCurrentDateTime() + "_dataFiles.json";
    const element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:application/json;charset=utf-8," + encodeURIComponent(text)
    );
    element.setAttribute("download", filename);
    element.style.display = "none";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
    // if success 
    for (const item of checkedData) {
      console.log( item );
      const fileData = JSON.parse(item.fileString);
      fileData.isExported = true;
      fileData.checked = false;
      await writeDataFile(fileData.filename, JSON.stringify(fileData));
    }
    await loadFiles();
  }
}

function getCurrentDateTime() {
  const date = new Date();
  const currentDate =
    date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
  const currentTime =
    date.getHours() + "-" + date.getMinutes() + "-" + date.getSeconds();

  return currentDate + "_" + currentTime;
}

function setAllOnOff($event) {
  console.log("checked selectAll:", $event.srcElement.id);
  for (const file in dataFiles.value) {
    dataFiles.value[file].checked = $event.detail.checked;
    console.log(dataFiles.value[file].checked);
  }
}

function setOnOff($event, file) {
  const fileString = file;

  if ($event.detail.checked == true) {
    selectedFiles.value ++;
    checkedData.push({
      uuid: $event.srcElement.id,
      fileString: JSON.stringify(fileString),
    });
  } else {
    selectedFiles.value --;
    checkedData.splice(checkedData.indexOf($event.srcElement.id), 1);
  }

  //FOR TESTING
  for (const file in checkedData) {
    console.log(
      "uuid:" + checkedData[file].uuid + " checked: " + $event.detail.checked
    );
  }
  console.log("Array-Size:" + checkedData.length);
}

function invertSelection() {
  //before check if item ckecked value is undefined, overwrite with false
  for (const file in dataFiles.value) {
    if (dataFiles.value[file].checked === undefined) {
      dataFiles.value[file].checked = false;
    }
  }
  //after check invert ckeck value
  for (const file in dataFiles.value) {
    dataFiles.value[file].checked = !dataFiles.value[file].checked;
    console.log(
      dataFiles.value[file].uuid + ":" + dataFiles.value[file].checked
    );
  }
}

async function isUploaded() {
  for (const file in dataFiles.value) {
    const { data, error } = await supabase
      .from("datafiles")
      .select()
      .eq("id", dataFiles.value[file].uuid);

    if (error) {
      console.log(JSON.stringify(error));
    }

    try {
      if (data[0].id) {
        dataFiles.value[file].isUp = true;
      }
    } catch (error) {
      dataFiles.value[file].isUp = false;
    }
  }
}
</script>

<style scoped>
.uploadStatus {
  font-size: 20px;
  margin-left: 0.4em;
}

.fileTitle {
  font-size: 13px;
  font-weight: bold;
  word-break: break-all;
}
.fileDetails {
  display: flex;
  justify-content: space-between;
}
.fileManagementButtons {
  display: block;
}
.fileButton {
  margin-right: 1em;
  margin-bottom: 1em;
}

.fileIcons {
  font-size: 1em;
}

.fileDate {
  font-size: 12px;
  color: var(--ion-color-medium, #92949c);
}
</style>
