<template>
  <ion-page>
    <ion-content class="ion-padding">
      <div
       class="cicada-section"
        v-for="section of state.sections"
        :key="section.id"
        v-show="section.visible"
      >
        <div 
          v-for="thing of section.modules" 
          :key="thing" 
          v-show="!thing.hide"
        >
          <component :is="thing.component" v-bind="thing.options" />
        </div>
      </div>
    </ion-content>
    <ion-footer v-if="state.sections?.length > 1">
      <ion-toolbar>
        <ion-segment :value="activeSection">
          <ion-segment-button
            v-for="section of state.sections"
            :key="section.id"
            :value="section.name"
            @click.prevent="switchSectionTo(section.name)"
          >
            <ion-icon :class="'bi bi-' + section.icon" />
            <ion-label>{{ section.name }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script setup>
import {
  IonSegment,
  IonSegmentButton,
  onIonViewWillLeave,
  onIonViewWillEnter,
} from "@ionic/vue";
import { computed, markRaw, reactive } from "vue";
import Modules from "../../modules";
import { useAppStateStore } from "@/store/app";
import { useRouter } from 'vue-router'
import { supabase } from "@/supabase/client";
import { getAppById } from "@/supabase/app";

const state = reactive({
  sections: [],
});
const appState = useAppStateStore();
const router = useRouter();
const appId = router.currentRoute.value.params.id;

onIonViewWillEnter( async () => {
  console.log("AppContainer mounted");
  loadApp(appId);
});

onIonViewWillLeave(() => {
  console.log("ion left view");
  state.sections = [];
  appState.unloadApp();
});

const activeSection = computed(() => {
  return state.sections.find((section) => {
    return section.visible;
  })?.name;
});

async function loadApp( appId ) {
  console.log("loading app", appId);
  console.log(Modules);
  /*
  const res = await supabase.from('apps').select().eq('id', appId); 
  console.log( 'supabase app:', res );

  if ( res.error || res.data.length != 1 ) {
    console.error('bad', res.error);
    return;
  }

  const appSetup = res.data[0];
  */
 
  const res = await getAppById(appId, appState.storedAvailableApps);
  console.log("current user app:", res)
  const appSetup = res;

  let id = 0;
  for (const sectionDefinition of appSetup.config) {
    console.log(sectionDefinition);
    const section = {
      id: id++,
      name: sectionDefinition.name,
      icon: sectionDefinition.icon,
      visible: false,
      modules: [],
    };

    for (const moduleDefinition of sectionDefinition.modules) {
      console.log(moduleDefinition);

      if (!Object.keys(Modules).includes(moduleDefinition.type)) {
        console.error("Cannot load module, type unknown.", moduleDefinition);
        continue;
      }
      
      console.log(Modules[moduleDefinition.type]);
      section.modules.push({
        component: markRaw(Modules[moduleDefinition.type]),
        options: {}, // set an empty default value for options in case the defintion doesn't provide any
        ...moduleDefinition,
      });
    }
    console.log(state.sections);
    state.sections.push(section);
  }
  console.log(state.sections);
  switchSectionTo(state.sections[0].name);
  appState.loadApp( appSetup  );
}

function switchSectionTo(sectionName) {
  if (
    state.sections.some((section) => {
      return section.name === sectionName;
    })
  ) {
    for (const section of state.sections) {
      section.visible = section.name === sectionName;
    }
  }
}
</script>

<style>
.cicada-section {
  max-width: 800px;
  margin: 0 auto;
}
</style>
