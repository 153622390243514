<template>
  <ion-page>
    <ion-row class="ion-justify-content-center">
      <ion-col size="12" size-sm="8">
        <ion-card>
          <ion-card-header color="primary">
            <ion-card-title> Log In </ion-card-title>
          </ion-card-header>
          <form @submit.prevent="onLogin">
            <ion-card-content>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <ion-item lines="full">
                    <ion-label position="floating">Email</ion-label>
                    <ion-input 
                      type="email" 
                      required 
                      v-model="email" a
                      utocomplete="email" 
                    />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <ion-item lines="full">
                    <ion-label position="floating">Password</ion-label>
                    <ion-input 
                      type="password" 
                      required 
                      v-model="password" 
                      autocomplete="current-password" 
                    />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col 
                  style="text-align:center;"  
                  size="12" 
                  size-sm="4"
                >
                  <ion-button 
                    type="submit" 
                    color="primary" 
                    expand="block"
                  >
                    Sign In
                  </ion-button>
                  <input 
                    type="submit" 
                    style="visibility: hidden; 
                           position: absolute; 
                           left: -1000px;" 
                  />
                  <a @click.prevent="onForgotPassword" href="#">Forgot Password?</a>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col />
              </ion-row>
            </ion-card-content>
          </form>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonButton,
  IonItem,
  IonInput,
  IonLabel,
  loadingController,
  alertController,
} from "@ionic/vue";
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { supabase } from "@/supabase/client";
import { useAuthStore } from "@/store/auth.js";
import stringConst from "@/assets/strings";

const email = ref("");
const password = ref("");
const router = useRouter();
const auth = useAuthStore();
let loading = undefined;

onMounted(async () => {
  loading = await loadingController.create({
    message: "Loading, please be patient.",
  });
});
async function onLogin() {
  console.log("Login with email:", email.value);
  console.log(loading);
  loading.present();

  const response = await supabase.auth.signInWithPassword({
    email: email.value,
    password: password.value,
  });
  console.log(response);

  if (response.error) {
    console.log("invalid credentials");

    const errorLogin = await alertController.create({
      header: 'Error Login',
      message: 'Invalid credentials. Either your login data are incorrect or maybe you are offline. You must be online to log in.',
      buttons: ['OK']
    });
    await errorLogin.present();

  } else {
    const userData = response?.data?.user;
    const { data: userProfile } = await supabase
      .from("profiles")
      .select()
      .eq("id", userData?.id);
    const { data: admin } = await supabase
      .from("admins")
      .select();
    console.log(admin);
    const combinedUserData = {
      ...userData,
      profile: userProfile[0],
      admin: admin?.length > 0,
    };
    auth.setLoggedIn(combinedUserData);
    password.value = "";
    router.push({ name: stringConst.routes.PROFILE });
  }
  loading.dismiss();
}

async function onForgotPassword() {
  console.log("forgot password.");
  router.push({ name: stringConst.routes.FORGOT_PASSWORD });
}
</script>
