<template>
  <ion-page>
    <ion-content v-if="appSetup">
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-button 
            class="ion-padding" 
            style="text-transform: none;"
            @click.prevent="goToAppOverview()"
            size="small"
          >
            Back to App overview
          </ion-button>
          <br />
          <br />
          <ion-card>
            <ion-card-header>
              <ion-card-title>App</ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12">
                  <ion-item>
                    <ion-label position="floating">App name</ion-label>
                    <ion-input placeholder="Awesome App" v-model="appSetup.name" />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12">
                  <ion-item>
                    <ion-label position="floating">Description</ion-label>
                    <ion-textarea placeholder="Some more information" v-model="appSetup.description" />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12">
                  <ion-text class="ion-padding" color="light">Upload Icon</ion-text>
                  <ion-item>
                    <input 
                      type="file" 
                      @change="onImageInputFileChange" 
                      accept="image/png, image/jpeg" 
                    />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12">
                  <ion-list>
                    <ion-item v-for="(section, sectionIndex) in appSetup.config" :key="sectionIndex">
                      <section-edit 
                        v-model="appSetup.config[sectionIndex]"
                        @removeMe="onClickConfirmation(sectionIndex, null, $event)"
                      />
                    </ion-item>
                    <ion-item 
                      fill="outline" 
                      lines="none" 
                      button 
                      @click="addEmptySection()"
                    >
                      <ion-icon 
                        slot="end" 
                        color="primary" 
                        class="bi bi-plus-circle" 
                      />
                      <ion-text color="primary"> Add Section </ion-text>
                    </ion-item>
                  </ion-list>
                </ion-col>
              </ion-row>
            </ion-card-content>
            <ion-row class="ion-justify-content-center">
              <ion-col 
                style="text-align:center;" 
                size="12" 
                size-sm="4"
              >
                <p>
                  <ion-button 
                    v-if="props.id !== 'new' && !routeFullPath.includes('/copy')" 
                    id="saveApp" 
                    :disabled="loading" 
                    @click="saveChanges($event)" 
                    expand="block"
                  >
                    {{ getSaveButtonDescription() }}
                  </ion-button>
                  <ion-button 
                    v-if="props.id !== 'new' && !routeFullPath.includes('/copy')" 
                    color="danger" 
                    :disabled="loading"
                    @click="onClickConfirmation(null, null, $event)" 
                    expand="block"
                  >
                   Delete
                  </ion-button>
                </p>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-col>
      </ion-row>
      <ion-row class="ion-justify-content-center">
        <ion-col size="12" size-sm="8">
          <ion-card>
            <ion-card-header>
              <ion-card-title> Users </ion-card-title>
            </ion-card-header>
            <ion-card-content>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12">
                  <ion-list>
                    <ion-item v-for="(user, userIndex) in appSetup.users" :key="userIndex">
                      {{ userNameFromId(user) }}
                      <ion-button fill="clear" @click="onClickConfirmation(null, user, $event)"> 
                        <ion-icon class="bi bi-x-lg" />
                      </ion-button>
                    </ion-item>
                    <ion-item fill="outline" lines="none">
                      <ion-select label="User" 
                        placeholder="User" 
                        interface="action-sheet"
                        v-model="selectedUser"
                      >
                        <ion-select-option v-for="user in addableUsers" :key="user" :value="user.id">
                          {{ userNameFromId(user.id) }}
                        </ion-select-option>
                      </ion-select>
                    </ion-item>
                    <ion-button size="small" @click="addUser()">Add User</ion-button>
                  </ion-list>
                </ion-col>
              </ion-row>
            </ion-card-content>
            <ion-row class="ion-justify-content-center">
              <ion-col 
                style="text-align:center;" 
                size="12" 
                size-sm="4"
              >
                <p>
                  <ion-button 
                    id="saveUser" 
                    :disabled="loading" 
                    @click="saveChanges($event)" 
                    expand="block"
                  >
                    {{ getSaveButtonDescription() }}
                  </ion-button>
                </p>
              </ion-col>
            </ion-row>
          </ion-card>
        </ion-col>
      </ion-row>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonList,
  IonSelect,
  IonSelectOption,
  alertController,
  IonInput,
  IonTextarea,
  IonText,
  IonRow,
  IonCol
} from "@ionic/vue";
import { ref, onMounted, computed } from "vue";
import Modules from "../../modules";
import { useRouter, onBeforeRouteLeave } from "vue-router";
import { supabase } from "@/supabase/client";
import {getAllUsers} from "@/supabase/user";
import { useAppStateStore } from "@/store/app";
import { getAppById, getAvailableApps } from "@/supabase/app";
import SectionEdit from "@/components/SectionEdit.vue";

const appSetup = ref({});
const loading = ref(true);
const goForwardAfterDelete = ref(false);
const props = defineProps(['id']);
const router = useRouter();
const routeFullPath = router.currentRoute.value.fullPath;
const appState = useAppStateStore();

console.log(router);
console.log("App Edit ");

onMounted(async () => {
  const appId = props.id;
  console.log("AppEdit mounted");
  loadApp(appId);
  getUsers();
});

const availableUsers = ref([]);
const selectedUser = ref(undefined);

const addableUsers = computed(() => {
  return availableUsers.value.filter((user) => {
    console.log(
      user.id,
      "is",
      appSetup.value.users?.includes(user.id) ? "added" : "not added"
    );
    return !appSetup.value.users?.includes(user.id);
  });
});

async function loadApp(appId) {
  console.log("loading app", appId);

  if (appId === "new") {
    appSetup.value = {
      name: 'New App',
      icon: undefined,
      description: "",
      config: [],
      users: [],
    }
    loading.value = false;
    return;
  }
  console.log(Modules);

  appSetup.value = await getAppById(appId, appState.storedAvailableApps);
  console.log(appSetup.value);
  loading.value = false;
}

async function saveChanges(event) {
  loading.value = true;
  console.log(appSetup.value);

  let res = {
    error: true,
  }

  if (!appSetup.value.id) {
    res = await supabase.from('apps').insert(appSetup.value).select();
  } else if( appSetup.value.id && routeFullPath.includes("/copy")) {    
    appSetup.value.id = undefined;
    res = await supabase.from('apps').insert(appSetup.value).select();
    goForwardAfterDelete.value = true;
    await refreshAppStore();
    goToAppOverview();
  } else {
    if(event.target.id == "saveApp"){
     appSetup.value.version_number = appSetup.value.version_number +1;
     appSetup.value.updated_at = getCurrentDateTime();
    }
    res = await supabase.from('apps').update(appSetup.value).eq('id', appSetup.value.id).select();
  }
  console.log("supabase app:", res);

  if (res.error) {
    console.error("bad", res.error);
    return;
  }
  appSetup.value = res.data[0];
  loading.value = false;
  await refreshAppStore();
}

function getCurrentDateTime(){
  const date = new Date();
  const currentdate = date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate();
  const currentTime = date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
  const currentDateTime = currentdate+' '+currentTime;
          
  return currentDateTime;
}

function addEmptySection() {
  appSetup.value.config.push({
    name: undefined,
    icon: undefined,
    modules: [],
  });
}

function getSaveButtonDescription(){
  return routeFullPath.includes('/copy') ?  'Save Copy' : 'Save';
}

function onImageInputFileChange(event) {
  const file = event.target.files[0];
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = e.target.result;
    console.log(data);
    // Save image as URL content string
    appSetup.value.image_url = e.target.result;
  };
  reader.readAsDataURL(file);
}

function goToAppOverview() {
  router.push({ path: "/admin/apps" });
}

function getAlertMessage(sectionIndex, removingUser) {

  const messages = {
    "app": "App will be deleted.",
    "user": "User will be removed.",
    "section": "Section will be deleted."
  };
  const append = " Are you sure?";

  if (removingUser) {
    return messages['user'] + append;
  } else if (sectionIndex != null) {
    return messages['section'] + append;
  } else {
    return messages['app'] + append;
  }
}

async function onClickConfirmation(sectionIndex, removingUser, _$event) {

  const confirmation = await alertController
    .create(
      {
        message: getAlertMessage(sectionIndex, removingUser),
        buttons: [{
          text: 'Ok',
          handler: () => {

            //remove user from app
            if (removingUser) {
              appSetup.value.users = appSetup.value.users.filter((user) => {
                return user !== removingUser;
              });
              //saveChanges($event);

              //remove sectionfrom app
            } else if (sectionIndex != null) {
              appSetup.value.config.splice(sectionIndex, 1);
              //saveChanges($event);

              //delete app  
            } else {
              onAppDelete(props.id);
            }
          }
        },
          'Cancel'
        ]
      }
    );
  await confirmation.present();
}

async function getUsers() {
  availableUsers.value = await getAllUsers();
}

function userNameFromId(id) {
  const user = availableUsers.value.find((user) => user.id === id);
  if (!user) return "REMOVE USER " + id;
  return user.email;
}

function addUser() {
  if (selectedUser.value) {
    appSetup.value.users.push(selectedUser.value);
    selectedUser.value = undefined;
  }
}

onBeforeRouteLeave(() => {
  if (goForwardAfterDelete.value == false) {
    const answer = window.confirm('All changes are saved?')
    if (!answer) return false;
  }
});

async function onAppDelete(appId) {
  console.log("delete app with id:" + appId);
  goForwardAfterDelete.value = true;

  const { error } = await supabase
    .from('apps')
    .delete()
    .eq('id', appId);
  
  if( !error ){
    await refreshAppStore();
    goToAppOverview();
  }else{
    console.log(error);
    const deleteStatus = await alertController.create({
      message: 'An error occured during deleting app. Please try it again later.',
      buttons: ["OK"],
    });
    await deleteStatus.present();
  }
}

async function refreshAppStore(){
  const availableApps = await getAvailableApps();
  appState.saveAvailableApps(availableApps);  
}

</script>
