<template>
  <ion-page>
    <ion-row class="ion-justify-content-center">
      <ion-col size="12" size-sm="8">
        <ion-card>
          <ion-card-header color="primary">
            <ion-card-title> Set new password </ion-card-title>
          </ion-card-header>
          <ion-card-content>
            <form @submit.prevent="onSubmit">
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <ion-item lines="full">
                    <ion-label position="floating">Password</ion-label>
                    <ion-input 
                      type="password" 
                      required 
                      v-model="password"
                    />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col size="12" size-sm="8">
                  <ion-item lines="full">
                    <ion-label position="floating">Password Confirmation</ion-label>
                    <ion-input 
                      type="password" 
                      required 
                      v-model="password2"
                    />
                  </ion-item>
                </ion-col>
              </ion-row>
              <ion-row class="ion-justify-content-center">
                <ion-col 
                  style="text-align:center;" 
                  size="12" 
                  size-sm="4"
                >
                  <ion-button 
                    type="submit" 
                    color="primary" 
                    expand="block"
                  >
                    Confirm
                  </ion-button>
                </ion-col>
              </ion-row>
            </form>
          </ion-card-content>
        </ion-card>
      </ion-col>
    </ion-row>
  </ion-page>
</template>

<script setup>
import {
  IonPage,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonRow,
  IonCol,
  IonItem,
  IonButton,
  IonLabel,
  IonInput,
  loadingController,
  alertController
} from "@ionic/vue";
import { useRouter } from "vue-router";
import { onMounted, ref } from "vue";
import { supabase } from "@/supabase/client";
import stringConst from '@/assets/strings';

const router = useRouter();
const password = ref("");
const password2 = ref("");
let loading = undefined;

onMounted(async () => {
  loading = await loadingController.create({
    message: "Loading, please be patient.",
  });
  loading.present();
  const response = await supabase.auth.getUser();
  console.log(response);

  if (response.error) {
    console.error("Invalid Session.");
  }
  loading.dismiss();
  return;
});

async function onSubmit() {
  const userResponse = await supabase.auth.getUser();
  console.log(userResponse);

  if (password.value !== password2.value) {
    console.log("mismatch");
    createErrorAlerts("Password must be identical with confirmation field!");
    return;
  }

  const passwordResponse = await supabase.auth.updateUser({
    password: password.value,
  });

  if (passwordResponse.error) {
    console.error(passwordResponse);
    createErrorAlerts(passwordResponse.error);
  } else {
    router.push({ name: stringConst.routes.PROFILE });
  }
}

async function createErrorAlerts(msg){
  const errorReset = await alertController.create({
      header: 'Error Passwort Reset',
      message: msg,
      buttons: ['OK']
    });
    await errorReset.present();
}
</script>

<style></style>
