import { supabase } from "@/supabase/client";

async function downloadFile(bucket, filename) {
  const { data, error } = await supabase.storage
    .from(bucket)
    .download(filename);
  if (error) {
    console.error(error);
    return null;
  }
  return data;
}

async function listFiles(bucket) {
  return await supabase.storage.from(bucket).list();
}

async function deleteFile(bucket, filename) {
  return await supabase.storage.from(bucket).remove([filename]);
}

async function uploadFile(bucket, file) {
  return await supabase.storage.from(bucket).upload(file.name, file);
}

export { downloadFile, listFiles, deleteFile, uploadFile };