
const bleUtils = {
  toString(dataView) {
    const decoder = new TextDecoder("utf-8");
    return decoder.decode(dataView);
  },
  toUint8(dataView) {
    const data = new Uint8Array(dataView.buffer);
    if (!dataView.byteLength) {
      throw new Error('dataView is empty');
    }
    return data[0];
  },
  toUint16(dataView) {
    const data = new Uint16Array(dataView.buffer);
    if (!dataView.byteLength) {
      throw new Error('dataView is empty');
    }
    return data[0];
  },
  toUint32(dataView) {
    const data = new Uint32Array(dataView.buffer);
    if (!dataView.byteLength) {
      throw new Error('dataView is empty');
    }
    return data[0];
  },
  toDataType(dataView, dataType) {
    if (!dataType) {
      throw new Error('datatype is undefined');
    }
    if (!dataView ) {
      throw new Error('dataView is undefined');
    }
    
    if ( dataType === "string" && dataView.byteLength === 0 ) {
      return "";
    }
    
    if (!dataView.byteLength) {
      throw new Error('dataView is empty');
    }
    switch (dataType) {
      case 'uint8':
        return bleUtils.toUint8(dataView);
      case 'uint16':
        return bleUtils.toUint16(dataView);
      case 'uint32':
        return bleUtils.toUint32(dataView);
      case 'string':
        return bleUtils.toString(dataView);
      default:
        throw new Error(`unknown datatype ${dataType}`);
    }
  },
}

export default bleUtils